<template>
  <div class="table-right">

    <template v-if="!isUserHasEasyOrder">
      <template v-if="newOrderLink && (
                _.has(this.currentPermissions, PERMISSIONS.ORDER_FBM_GENERAL) ||
                _.has(this.currentPermissions, PERMISSIONS.ORDER_EXPRESS_GENERAL)
            )">
        <div class="table-right__item">
          <LinkButton
              :value="$t('importO_newOrder.localization_value.value')"
              @click.native="$emit('showWhatOrderCreatePopup', item, getExpressLinks, getFBMLinks)"
          />
        </div>
      </template>

      <template v-if="_.has(this.currentPermissions, PERMISSIONS.ORDER_FBM_GENERAL) && getFBMLinks && !newOrderLink">
        <div class="table-right__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <span class="admin-edit" @click="editTranslate([
                      'cuspayments_usualOrder',
                    ])"></span>
          <LinkButton
              :value="$t('cuspayments_usualOrder.localization_value.value')"
              @click.native="$emit('showChooseOrderFBMCreatePopup', item, getFBMLinks)"
          />
        </div>
      </template>

      <!--  Order Express  -->

      <template v-if="getExpressLinks && !newOrderLink">
        <div class="table-right__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <span class="admin-edit" @click="editTranslate([
                      'cuspayments_expressOrder',
                    ])"></span>
          <LinkButton
              :value="$t('cuspayments_expressOrder.localization_value.value')"
              @click.native="$emit('showChooseExpressOrder', item, getExpressLinks)"
          />
        </div>
      </template>


      <!--  Order Internal  -->

      <!--    <template v-if="_.has(currentPermissions, PERMISSIONS.ORDER_INTERNAL_BELPOSHTA) && !isAdmin">-->
      <!--      <router-link-->
      <!--          v-if="(isBelarus || isAdmin) && checkOrderBelPoshta"-->
      <!--          :to="checkOrderBelPoshta"-->
      <!--      >-->
      <!--        &lt;!&ndash;              class="table-right__ico"&ndash;&gt;-->
      <!--        <BelposhtaBtn/>-->
      <!--      </router-link>-->
      <!--      <div v-else style="width: 16px;"></div>-->
      <!--    </template>-->

      <template v-if="_.has(currentPermissions, PERMISSIONS.ORDER_INTERNAL_UKRPOSHTA)">
        <!--      <router-link-->
        <!--          v-if="(!isBelarus || isAdmin) && checkOrderUkrPoshta"-->
        <!--          :to="checkOrderUkrPoshta"-->
        <!--      >-->
        <!--        &lt;!&ndash;        class="table-right__ico"&ndash;&gt;-->
        <!--&lt;!&ndash;        <UkrposhtaBtn/>&ndash;&gt;-->
        <!--        <LinkButton-->
        <!--            :value="$t('cuspayments_ukrposhta.localization_value.value')"-->
        <!--            :minContentWidth="$store.getters.GET_LANG === UA_LANG"-->
        <!--        />-->

        <!--      </router-link>-->
        <div class="table-right__item"
             v-if="(!isBelarus || isAdmin) && checkOrderUkrPoshta"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <span class="admin-edit" @click="editTranslate([
                      'cuspayments_ukrposhta',
                    ])"></span>
          <LinkButton
              :value="$t('cuspayments_ukrposhta.localization_value.value')"
              :link="checkOrderUkrPoshta"
          />
        </div>
        <div v-else style="width: 16px;"></div>
      </template>
    </template>

    <template v-else>
      <div class="table-right__item" v-if="!item.order_fbm_attachment && !item.order_express_attachment">
        <LinkButton
            :value="$t('importO_easyOrder.localization_value.value')"
            @click.native="$emit('showChooseEasyOrderCreatePopup', item)"
        />
      </div>

      <div class="table-right__item" v-else-if="checkEasyOrderComplete">
        <LinkButton
            :value="$t('importO_easyOrder.localization_value.value')"
            :link="checkEasyOrderLink"
        />
      </div>
    </template>


  </div>
</template>

<script>
// import BelposhtaBtn from "../../Buttons/BelposhtaBtn/BelposhtaBtn";
// import UkrposhtaBtn from "../../Buttons/UkrposhtaBtn/UkrposhtaBtn";
import LinkButton from "../../../UI/buttons/LinkButton/LinkButton";
import {
  EXPRESS_DELIVERY_SERVICES,
  INTERNAL_DELIVERY_SERVICES,
  EXPRESS_DELIVERY_STATUSES,
  INTERNAL_STATUSES, FBM_ORDER_TYPES, FBM_STATUSES,
  UA_LANG
} from "../../../../staticData/staticVariables";

export default {
  name: "ManageOrdersInTable",
  components: {
    LinkButton,
    // UkrposhtaBtn,
    // BelposhtaBtn
  },

  props: {
    item: Object,
    index: Number,
    typeTransaction: String,
    newOrderLink: {
      type: Boolean,
      default: false
    },
  },

  watch: {},

  computed: {

    checkEasyOrderComplete() {
      let orderExpress = this.item.order_express_attachment
      let orderFbm = this.item.order_fbm_attachment

      if (orderExpress?.status === EXPRESS_DELIVERY_STATUSES.COMPLETED_STATUS || orderFbm?.status === FBM_STATUSES.FBM_COMPLETED_STATUS.value) {
        return false
      }
      return true
    },

    checkEasyOrderLink() {
      let orderExpress = this.item.order_express_attachment
      let orderFbm = this.item.order_fbm_attachment
      let consolidationTypeId = FBM_ORDER_TYPES.FBM_ORDER_CONSOLIDATION.id
      let warehouseTypeId = FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.id

      if(orderExpress) {
        return this.$store.getters.GET_PATHS.easyOrderEdit + '/fedex/' + orderExpress.id
      }

      if(orderFbm) {
        if(orderFbm.order_type_id === consolidationTypeId)
          return this.$store.getters.GET_PATHS.easyOrderEdit + '/consolidation/' + orderFbm.id
        if(orderFbm.order_type_id === warehouseTypeId)
          return this.$store.getters.GET_PATHS.easyOrderEdit + '/warehouse/' + orderFbm.id
      }

      return ''
    },

    hasExpress() {
      let instance = this.item.orderinstance || this.item.orderInstance
      let DHLServiceId = EXPRESS_DELIVERY_SERVICES.DHL_DELIVERY_SERVICE.id
      let FedexServiceId = EXPRESS_DELIVERY_SERVICES.FEDEX_DELIVERY_SERVICE.id
      let TNTServiceId = EXPRESS_DELIVERY_SERVICES.TNT_DELIVERY_SERVICE.id
      let deliveryService = this._.find(instance, function (o) {
        return (o.delivery_service_id === DHLServiceId ||
            o.delivery_service_id === FedexServiceId ||
            o.delivery_service_id === TNTServiceId);
      })
      if(deliveryService) {
        return true
      }
      return false
    },

    hasUkrPoshta() {
      let instance = this.item.orderinstance || this.item.orderInstance
      let serviceId = INTERNAL_DELIVERY_SERVICES.UKRPOSHTA_DELIVERY_SERVICE.id
      return this._.has(instance, serviceId)
    },


    checkOrderBelPoshta() {
      // if(this.item.order_express_attachment) return false

      let order = this.item.order_internal

      return this.getInternalLink({
        order,
        link: this.$store.getters.GET_PATHS.belposhta
      })

    },


    checkOrderUkrPoshta() {
      // if(this.item.order_express_attachment) return false

      let order = this.item.order_internal

      return this.getInternalLink({
        order,
        link: this.$store.getters.GET_PATHS.ukrposhta
      })
    },

    isUkrainianUser() {
      if(this.isAdmin) return true

      if(!this.isUkrainian && !this.isUserAlternativeContactUkraine) return false

      return true
    },

    getExpressLinks() {
      // if(this.item.order_internal || this.item.order_internal_attachment) return false

      let order = this.item.order_express_attachment
      let DHLServiceId = EXPRESS_DELIVERY_SERVICES.DHL_DELIVERY_SERVICE.id
      let FedexServiceId = EXPRESS_DELIVERY_SERVICES.FEDEX_DELIVERY_SERVICE.id
      let TNTServiceId = EXPRESS_DELIVERY_SERVICES.TNT_DELIVERY_SERVICE.id
      let NPGServiceId = EXPRESS_DELIVERY_SERVICES.NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.id
      let UPSServiceId = EXPRESS_DELIVERY_SERVICES.UPS_DELIVERY_SERVICE.id

      if (!this._.has(this.currentPermissions, this.PERMISSIONS.ORDER_EXPRESS_GENERAL)) return false

      // let deliveryService = this._.find(instance, function (o) {
      //   return (o.delivery_service_id === DHLServiceId ||
      //       o.delivery_service_id === FedexServiceId ||
      //       o.delivery_service_id === TNTServiceId);
      // })


      if (!order) {
        let country = this.typeTransaction === 'order' ?
            this.item?.country : this.item?.payment_transaction_detail?.payment_transaction_contact?.countryInstance

        let dhl = this.checkDHLExpressUserFromGB(DHLServiceId) ? `/create/dhl/${this.typeTransaction}/${this.item.id}` : null
        let tnt = this.checkUkrainianUserInTNT(TNTServiceId) && (!country || this.checkTNTAllCountriesOrUSAGroup(country)) ?
            `/create/tnt/${this.typeTransaction}/${this.item.id}` : null
        let fedex = `/create/fedex/${this.typeTransaction}/${this.item.id}`
        let npg = `/create/npg/${this.typeTransaction}/${this.item.id}`
        let ups = `/create/ups/${this.typeTransaction}/${this.item.id}`

        return {
          dhl: this._.has(this.currentPermissions, this.PERMISSIONS.ORDER_EXPRESS_DHL) && !this.isPoland && !this.isUserAlternativeContactPoland
              ? dhl : '',
          tnt: this._.has(this.currentPermissions, this.PERMISSIONS.ORDER_EXPRESS_TNT) ? tnt : '',
          fedex: this._.has(this.currentPermissions, this.PERMISSIONS.ORDER_EXPRESS_FEDEX) ? fedex : '',
          npg: this._.has(this.currentPermissions, this.PERMISSIONS.ORDER_EXPRESS_NPG) && this.isUkrainianUser ? npg : '',
          ups: this._.has(this.currentPermissions, this.PERMISSIONS.ORDER_EXPRESS_UPS) && this.isUkrainianUser ? ups : '',
        }
      }

      if (order?.status === EXPRESS_DELIVERY_STATUSES.COMPLETED_STATUS) {
        return false
      }

      if (order?.status === EXPRESS_DELIVERY_STATUSES.IN_PROGRESS_STATUS) {

        switch (order.delivery_service_id) {
          case DHLServiceId:
            return {
              dhl: `/edit/dhl/${order.id}`,
              tnt: null,
              fedex: null
            }
          case FedexServiceId:
            return {
              dhl: null,
              tnt: null,
              fedex: `/edit/fedex/${order.id}`
            }
          case TNTServiceId:
            return {
              dhl: null,
              tnt: `/edit/tnt/${order.id}`,
              fedex: null
            }
          case NPGServiceId:
            return {
              dhl: null,
              tnt: `/edit/npg/${order.id}`,
              fedex: null
            }
          case UPSServiceId:
            return {
              dhl: null,
              tnt: `/edit/ups/${order.id}`,
              fedex: null
            }
        }
      }

      return false
    },

    // getFBMLinks() {
    //   let label = this.getUserFBMCreateLabelSetting ?
    //     `/create/${FBM_ORDER_TYPES.FBM_ORDER_LABEL.name}/${this.typeTransaction}/${this.item.id}` : null
    //
    //   return {
    //     consolidation: `/create/${FBM_ORDER_TYPES.FBM_ORDER_CONSOLIDATION.name}/${this.typeTransaction}/${this.item.id}`,
    //     warehouse: `/create/${FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.name}/${this.typeTransaction}/${this.item.id}`,
    //     label: label,
    //   }
    // }

    getFBMLinks() {
      let order = this.item.order_fbm_attachment
      let consolidationTypeId = FBM_ORDER_TYPES.FBM_ORDER_CONSOLIDATION.id
      let warehouseTypeId = FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.id
      let labelTypeId = FBM_ORDER_TYPES.FBM_ORDER_LABEL.id

      if (!this._.has(this.currentPermissions, this.PERMISSIONS.ORDER_FBM_GENERAL)) return false

      // let orderType = this._.find(instance, function (o) {
      //   return (o?.order_type_id === consolidationTypeId ||
      //       o?.order_type_id === warehouseTypeId ||
      //       o?.order_type_id === labelTypeId);
      // })

      let prepayment = order?.prepay

      if (prepayment === 1) {
        return false
      }

      if (!order) {
        // let label = this.getUserFBMCreateLabelSetting ?
        //     `/create/${FBM_ORDER_TYPES.FBM_ORDER_LABEL.name}/${this.typeTransaction}/${this.item.id}` : null
        let label = `/create/${FBM_ORDER_TYPES.FBM_ORDER_LABEL.name}/${this.typeTransaction}/${this.item.id}`
        let consolidation = `/create/${FBM_ORDER_TYPES.FBM_ORDER_CONSOLIDATION.name}/${this.typeTransaction}/${this.item.id}`
        let warehouse = `/create/${FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.name}/${this.typeTransaction}/${this.item.id}`

        return {
          consolidation: this._.has(this.currentPermissions, this.PERMISSIONS.ORDER_FBM_CONSOLIDATION) ? consolidation : '',
          warehouse: this._.has(this.currentPermissions, this.PERMISSIONS.ORDER_FBM_WAREHOUSE) ? warehouse : '',
          label: this._.has(this.currentPermissions, this.PERMISSIONS.ORDER_FBM_LABEL) ? label : '',
        }
      }

      if (order?.status === FBM_STATUSES.FBM_COMPLETED_STATUS.value) {
        return false
      }

      if (order.status === FBM_STATUSES.FBM_IN_PROGRESS_STATUS.value) {

        switch (order.order_type_id) {
          case consolidationTypeId:
            return {
              consolidation: `/consolidation/${order.id}`,
              warehouse: null,
              label: null,
            }
          case warehouseTypeId:
            return {
              consolidation: null,
              warehouse: `/warehouse/${order.id}`,
              label: null,
            }
          case labelTypeId:
            return {
              consolidation: null,
              warehouse: null,
              label: `/label/${order.id}`,
            }
        }
      }

      return false
    }

  },

  data() {
    return {
      UA_LANG: UA_LANG
    }
  },

  methods: {
    getInternalLink({order, link}) {
      if (!order) {
        return `${link}/create/${this.typeTransaction}/${this.item.id}`
      }

      if (order.order_internal_status_id === INTERNAL_STATUSES.COMPLETE) {
        return `${link}/show/${order.id}`
      }

      if (order.order_internal_status_id === INTERNAL_STATUSES.IN_PROGRESS.id) {
        return `${link}/${order.id}`
      }

      return false
    }
  },

}
</script>

<style scoped>
.table-right__item{
  margin-left: 0;
  margin-right: 10px;
}
</style>
